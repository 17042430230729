import { render, staticRenderFns } from "./StudentAnecdotals.vue?vue&type=template&id=adbea8b2"
import script from "./StudentAnecdotals.vue?vue&type=script&lang=ts"
export * from "./StudentAnecdotals.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('adbea8b2')) {
      api.createRecord('adbea8b2', component.options)
    } else {
      api.reload('adbea8b2', component.options)
    }
    module.hot.accept("./StudentAnecdotals.vue?vue&type=template&id=adbea8b2", function () {
      api.rerender('adbea8b2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/StudentAnecdotals.vue"
export default component.exports