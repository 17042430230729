var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("StudentSubHeader", {
        attrs: { "show-marking-period-picker": false },
        scopedSlots: _vm._u([
          {
            key: "left-buttons",
            fn: function () {
              return [
                _vm.$_userMixins_userRole == "School Admin"
                  ? _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottomleft",
                            modifiers: { hover: true, bottomleft: true },
                          },
                        ],
                        staticClass: "btn btn-icon kt-subheader__btn",
                        attrs: {
                          to: { name: "AnecdotalSetup" },
                          title: "Anecdotal Settings",
                        },
                      },
                      [_c("i", { staticClass: "la la-cog" })]
                    )
                  : _vm._e(),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.bottomleft",
                        modifiers: { hover: true, bottomleft: true },
                      },
                    ],
                    staticClass: "btn btn-icon kt-subheader__btn-primary",
                    attrs: { href: "#", title: "Export To CSV" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.exportData.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "flaticon-download-1" })]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "toolbar-dropdown",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "btn-wide btn-clean btn-clean-primary",
                    attrs: {
                      "toggle-class": "btn btn-clean btn-sm",
                      variant: "none",
                      size: "md",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "pull-left" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.schoolTerm
                                      ? _vm.schoolTerm.name
                                      : "Yearlong"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.applyFilter(null)
                          },
                        },
                      },
                      [_vm._v(" Yearlong ")]
                    ),
                    _vm._l(_vm.schoolTerms, function (term) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: term.schoolTermId,
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.applyFilter(term)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(term.name) + " ")]
                      )
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm.student
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 col-lg-5 col-xl-4" },
                  [
                    _c("StudentHeaderPortlet", {
                      attrs: { "show-top-badge": false },
                    }),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "kt-portlet kt-portlet--height-fluid" },
                        [
                          _vm._m(0),
                          _c(
                            "div",
                            { staticClass: "kt-portlet__body" },
                            [
                              _c("StudentAnecdotalFilters", {
                                staticClass: "kt-portlet--height-fluid",
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12 col-lg-7 col-xl-8" },
                  [
                    _c("StudentAnecdotalTotalsPortlet"),
                    _vm.student
                      ? _c("AnecdotalLogs", {
                          key: `anecdotal_logs_${_vm.key}`,
                          attrs: {
                            "lock-by-student-id": _vm.student.studentId,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Filters "),
        ]),
      ]),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }