<template>
<div class="kt-portlet">
    <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
            <div class="col-lg-12 col-xl-6">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <AchievementBadge
                            v-if="false"
                            :badge="topBadge"
                            :earned="true"
                            badge-size="lg"
                        />
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                Total Points
                            </h4>
                            <span class="kt-widget24__desc">
                                {{ totalEvents }} events
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt-font-brand">
                            <animated-number
                                :value="totalPoints"
                                :duration="1250"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                Earned
                            </h4>
                            <!-- <span class="kt-widget24__desc">
                                {{ totalPositivePoints }} points
                            </span> -->
                        </div>
                        <span class="kt-widget24__stats kt-font-success">
                            <animated-number
                                :value="totalPositivePoints"
                                :duration="500"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                    <!-- <div class="progress progress--sm">
                        <div
                            class="progress-bar kt-bg-warning"
                            role="progressbar"
                            style="width: 84%;"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div> -->
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            {{ totalPositivePoints }} points
                        </span>
                        <!-- <span class="kt-widget24__number">
                            84%
                        </span> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                Deductions
                            </h4>
                            <!-- <span class="kt-widget24__desc">
                                {{ totalResolved }} resolved
                            </span> -->
                        </div>
                        <span class="kt-widget24__stats kt-font-danger">
                            <animated-number
                                :value="totalNegativePoints"
                                :duration="500"
                                :delay="250"
                                :format-value="formatPoints"
                            />
                        </span>
                    </div>
                    <!-- <div class="progress progress--sm">
                        <div
                            class="progress-bar kt-bg-danger"
                            role="progressbar"
                            style="width: 69%;"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div> -->
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            {{ totalResolved }} resolved
                        </span>
                        <!-- <span class="kt-widget24__number">
                            69%
                        </span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import AnimatedNumber from 'animated-number-vue';
import studentMixins from '../store/mixins/studentMixins';
import utilMixins from '../store/mixins/utilMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import userMixins from '../store/mixins/userMixins';
import * as network from '../network';
import AchievementBadge from './AchievementBadge.vue';

export default Vue.extend({
    name: 'StudentAnecdotalTotalsPortlet',
    components: {
        AnimatedNumber,
        AchievementBadge,
    },
    mixins: [
        studentMixins,
        utilMixins,
        badgeMixins,
        userMixins,
    ],
    data() {
        return {
            aggregateStudentAnecdotalCategories: [],
        };
    },
    computed: {
        key() {
            return this.$store.state.database.reloadKey;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        schoolBadges() {
            const { gradeLevel } = this.student;
            // remove badges not valid for this student
            return this.$store.state.database.badges.filter((b) => {
                if (b.deleted) return false;
                // this isnt by grde level, show it
                if (b.badgeLimiterType == null) return true;

                // limited by grade level
                if (b.badgeLimiterType == 'Grade Level') {
                    const hasGLReqs = b.badgeRequirements.filter((br) => br.badgeRequirementLimiter == gradeLevel);
                    return (hasGLReqs.length > 0);
                }
                return false;
            });
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        topBadge() {
            return this.$_badgeMixins_getStickyBadgeFromRoute || this.$_badgeMixins_getAchievementLevelFromRoute;
        },
        earnedBadges() {
            return this.$_badgeMixins_getEarnedBadgesFromRoute;
        },
        possibleBadges() {
            const { earnedBadges } = this;
            return this.schoolBadges.map((b) => {
                const badge = { ...b };
                badge.earned = earnedBadges.find((eb) => eb.badgeId === b.badgeId);
                return badge;
            });
        },
        totalEvents() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalEvents), 0);
        },
        totalPoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalPoints), 0);
        },
        totalPositivePoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalPositivePoints), 0);
        },
        totalNegativePoints() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalNegativePoints), 0);
        },
        totalResolved() {
            return this.categoryTotals.reduce((acc, row) => acc + parseFloat(row.totalResolved), 0);
        },
        categoryTotals() {
            const { anecdotalEventCategories } = this.$store.state.database;
            const { schoolTerms } = this.$store.state.user;
            // if (!this.studentBadge) return [];
            const { aggregateStudentAnecdotalCategories } = this;
            return aggregateStudentAnecdotalCategories.map((row) => {
                const record = { ...row };
                const { anecdotalEventCategoryId } = row;
                record.key = `${row.anecdotalEventCategoryId}_${row.schoolTermId}`;
                record.category = anecdotalEventCategories.find((category) => category.anecdotalEventCategoryId == anecdotalEventCategoryId) || null;
                const schoolTerm = schoolTerms.find((term) => term.schoolTermId == row.schoolTermId) || null;
                record.schoolTerm = schoolTerm;
                return { ...record };
            }).filter((row) => row.category && row.schoolTerm);
        },
    },
    watch: {
        key() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        formatPoints(value) {
            return this.formatNumber(value);
        },
        populate() {
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const params = {
                url: {
                    studentId: this.student.studentId,
                    schoolId,
                    schoolTermId,
                },
            };
            network.student.getAggregatesForStudent(params, (err, resp) => {
                if (err) return this.showError(err);
                this.aggregateStudentAnecdotalCategories = resp.aggregateStudentAnecdotalCategories;
            });
        },
        showStudentBadgeDetails(badgeId) {
            // const { showError } = this;
            const params = { ...this.$route.params };
            params.badgeId = badgeId;
            this.$router.push({
                name: 'StudentBadgeDetails',
                params,
            });
        },
    },
});

</script>

<style scoped>

ul.badges {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: center;
}

ul.badges li {
    margin: 6px;
    display: inline-block;
}

span.kt-widget__label {
    min-width: 110px;
}
a.kt-widget__data i {
    display: none;
}
a.kt-widget__data:hover i {
    display: inline-block;
}
div.kt-widget__media {
    width: 90px;
    height: 90px;
}
img.kt-widget__img {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: 90px;
}

img.big-picture {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: auto;
}
</style>
