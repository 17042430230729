<template>
<div :key="`key_${title}_${key}`">
    <StudentSubHeader
        :show-marking-period-picker="false"
    >
        <template #left-buttons>
            <router-link
                v-if="$_userMixins_userRole == 'School Admin'"
                v-b-tooltip.hover.bottomleft
                :to="{name: 'AnecdotalSetup'}"
                title="Anecdotal Settings"
                class="btn btn-icon kt-subheader__btn"
            >
                <i class="la la-cog" />
            </router-link>

            <a
                v-b-tooltip.hover.bottomleft
                href="#"
                title="Export To CSV"
                class="btn btn-icon kt-subheader__btn-primary"
                @click.stop.prevent="exportData"
            >
                <i class="flaticon-download-1" />
            </a>
        </template>
        <template #toolbar-dropdown />
        <template #buttons>
            <b-dropdown
                toggle-class="btn btn-clean btn-sm"
                variant="none"
                size="md"
                class="btn-wide btn-clean btn-clean-primary"
            >
                <template #button-content>
                    <span class="pull-left">
                        {{ schoolTerm ? schoolTerm.name : 'Yearlong' }}
                    </span>
                </template>
                <b-dropdown-item
                    @click.stop.prevent="applyFilter(null)"
                >
                    Yearlong
                </b-dropdown-item>
                <b-dropdown-item
                    v-for="term in schoolTerms"
                    :key="term.schoolTermId"
                    @click.stop.prevent="applyFilter(term)"
                >
                    {{ term.name }}
                </b-dropdown-item>
            </b-dropdown>
        </template>
    </StudentSubHeader>

    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div v-if="student" class="row">
            <div class="col-md-12 col-lg-5 col-xl-4">
                <StudentHeaderPortlet :show-top-badge="false" />
                <div>
                    <div class="kt-portlet kt-portlet--height-fluid">
                        <div class="kt-portlet__head">
                            <div class="kt-portlet__head-label">
                                <h3 class="kt-portlet__head-title">
                                    Filters
                                </h3>
                            </div>
                            <div class="kt-portlet__head-toolbar" />
                        </div>
                        <div class="kt-portlet__body">
                            <StudentAnecdotalFilters class="kt-portlet--height-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-7 col-xl-8">
                <StudentAnecdotalTotalsPortlet />
                <AnecdotalLogs
                    v-if="student"
                    :key="`anecdotal_logs_${key}`"
                    :lock-by-student-id="student.studentId"
                />
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import AnecdotalLogs from './anecdotals/AnecdotalLogs.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import StudentAnecdotalTotalsPortlet from '../components/StudentAnecdotalTotalsPortlet.vue';
import StudentAnecdotalFilters from '../components/StudentAnecdotalFilters.vue';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import StudentSubHeader from './StudentSubHeader.vue';
import globalMixins from '../store/mixins/globalMixins';
import userMixins from '../store/mixins/userMixins';
import Types from '../store/Types';

export default {
    name: 'StudentAnecdotals',
    components: {
        StudentSubHeader,
        AnecdotalLogs,
        StudentAnecdotalTotalsPortlet,
        StudentAnecdotalFilters,
        StudentHeaderPortlet,
    },
    mixins: [studentMixins, portfolioMixins, userMixins, globalMixins],
    data() {
        return {
            key: 0,
            schoolTerm: null,
        };
    },
    computed: {
        cache() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            const { cache } = this.$store.state.database;
            return Boolean(cache.find((k) => k.cacheKey == cacheKey) || null);
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        hasBadge() {
            return Boolean(this.studentBadge);
        },
        schoolBadges() {
            return this.$store.state.database.badges;
        },
        topBadge() {
            if (!this.studentBadge) return null;
            return this.schoolBadges.find((b) => b.badgeId === this.studentBadge.badgeId) || null;
        },
        earnedBadges() {
            if (!this.studentBadge) return [];
            const { schoolBadges } = this;
            return this.studentBadge.earnedBadges.map((b) => schoolBadges.find((badge) => badge.badgeId === b.badgeId) || null);
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        studentBadges() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        isStudentView() {
            const homeUser = this.$_userMixins_isHomeUser;
            const schoolUser = this.$_userMixins_isSchoolUser;

            if (homeUser) return true;
            if (schoolUser) {
                if (this.forceStudentView) {
                    return true;
                }
                return false;
            }
            return true;
        },
        schoolTerms() {
            return this.$store.state.database.schoolTerms;
        },
        databaseIsReady() {
            return Boolean(this.$store.state.database.loadState === 'READY');
        },
    },
    watch: {
        '$route.params.studentEnrollmentId': {
            handler() {
                this.key += 1;
            },
        },
        databaseIsReady: {
            handler() {
                this.setup();
            },
        },
        cache: {
            handler() {
                this.key += 1;
            },
        },
    },
    mounted() {
        this.setup();
    },
    methods: {
        setup() {
            // const { schoolTerms } = this;
            // if (schoolTerms.length == 0) return;
            // const [schoolTerm] = schoolTerms;
            // const { schoolTermId } = schoolTerm;
            // if (schoolTermId) this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM, schoolTermId);
            // this.schoolTerm = schoolTerm;
        },
        exportData() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL, true);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
        newAnecdotal() {
            const { studentEnrollmentId } = this.$route.params;
            if (studentEnrollmentId) {
                const student = this.$store.state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
                return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
        applyFilter(term) {
            this.schoolTerm = term;
            this.$store.commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM, term ? term.schoolTermId : null);
            this.$store.dispatch(Types.actions.REFRESH_ANECDOTAL_EVENTS);
        },
    },
};
</script>
