var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit" }, [
      _c("div", { staticClass: "row row-no-padding row-col-separator-lg" }, [
        _c("div", { staticClass: "col-lg-12 col-xl-6" }, [
          _c("div", { staticClass: "kt-widget24" }, [
            _c(
              "div",
              { staticClass: "kt-widget24__details" },
              [
                false
                  ? _c("AchievementBadge", {
                      attrs: {
                        badge: _vm.topBadge,
                        earned: true,
                        "badge-size": "lg",
                      },
                    })
                  : _vm._e(),
                _c("div", { staticClass: "kt-widget24__info" }, [
                  _c("h4", { staticClass: "kt-widget24__title" }, [
                    _vm._v(" Total Points "),
                  ]),
                  _c("span", { staticClass: "kt-widget24__desc" }, [
                    _vm._v(" " + _vm._s(_vm.totalEvents) + " events "),
                  ]),
                ]),
                _c(
                  "span",
                  { staticClass: "kt-widget24__stats kt-font-brand" },
                  [
                    _c("animated-number", {
                      attrs: {
                        value: _vm.totalPoints,
                        duration: 1250,
                        delay: 250,
                        "format-value": _vm.formatPoints,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
          _c("div", { staticClass: "kt-widget24" }, [
            _c("div", { staticClass: "kt-widget24__details" }, [
              _vm._m(0),
              _c(
                "span",
                { staticClass: "kt-widget24__stats kt-font-success" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.totalPositivePoints,
                      duration: 500,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget24__action" }, [
              _c("span", { staticClass: "kt-widget24__change" }, [
                _vm._v(" " + _vm._s(_vm.totalPositivePoints) + " points "),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-lg-6 col-xl-3" }, [
          _c("div", { staticClass: "kt-widget24" }, [
            _c("div", { staticClass: "kt-widget24__details" }, [
              _vm._m(1),
              _c(
                "span",
                { staticClass: "kt-widget24__stats kt-font-danger" },
                [
                  _c("animated-number", {
                    attrs: {
                      value: _vm.totalNegativePoints,
                      duration: 500,
                      delay: 250,
                      "format-value": _vm.formatPoints,
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-widget24__action" }, [
              _c("span", { staticClass: "kt-widget24__change" }, [
                _vm._v(" " + _vm._s(_vm.totalResolved) + " resolved "),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget24__info" }, [
      _c("h4", { staticClass: "kt-widget24__title" }, [_vm._v(" Earned ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget24__info" }, [
      _c("h4", { staticClass: "kt-widget24__title" }, [_vm._v(" Deductions ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }